import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessDone = () => {
  const history = useNavigate();

  return (
    <div className="container mx-auto">
      <div
        className="flex flex-col items-center justify-between"
        style={{ height: "100vh" }}
      >
        <div>
          <div className="banner-img card-banner"></div>
          <div className="wrap-body">
            <div className="line"></div>
            <div className="text">
              <div className="text-content">
                <p>
                  Bộ phận Nhân sự cảm ơn bạn đã cung cấp thông tin cá nhân.
                  Chúng tôi sẽ đảm bảo mọi thông tin được bảo mật an toàn và sử
                  dụng nó để hỗ trợ bạn tốt nhất trong quá trình làm việc. Hy
                  vọng bạn sẽ có một hành trình thú vị và phát triển trong sự
                  nghiệp cùng chúng tôi!
                </p>
                <p style={{ marginTop: "15px" }}>
                  <button
                    onClick={() => {
                      history(-1);
                    }}
                  >
                    <b>Điền lại form</b>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default SuccessDone;
