import React from "react";
import { useNavigate } from "react-router-dom";

const SuccessConfirm = () => {
  const history = useNavigate();

  return (
    <div className="container mx-auto">
      <div
        className="flex flex-col items-center justify-between"
        style={{ height: "100vh" }}
      >
        <div>
          <div className="banner-img card-banner"></div>
          <div className="wrap-body">
            <div className="line"></div>
            <div className="text">
              <div className="text-content">
                <p>
                  Bộ phận Tuyển dụng đã nhận được thông tin bổ sung của bạn. Cảm
                  ơn bạn đã xác nhận tham gia phỏng vấn theo thông tin như trên
                  thư mời chúng tôi đã gửi.
                </p>
                <div style={{ height: 10 }}></div>
                <p>
                  Mọi yêu cầu hỗ trợ, Bạn vui lòng liên hệ Bộ phận Tuyển dụng –
                  0934.785.500 Chúng tôi xin gửi lời chúc tốt đẹp nhất đến Bạn
                  trong buổi phỏng vấn sắp tới & Chúc Bạn có một buổi phỏng vấn
                  thành công! Hẹn gặp Bạn tại buổi phỏng vấn.
                </p>
                <p style={{ marginTop: "15px" }}>
                  <button
                    onClick={() => {
                      history(-1);
                    }}
                  >
                    <b>Điền lại form</b>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default SuccessConfirm;
