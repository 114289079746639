import axios from 'axios';

// Tạo một instance của axios với cấu hình cơ bản
const instance = axios.create({
//   baseURL: 'https://api.example.com/',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
    "company-id": 1,
    "token": "Hpjdrxt41idNG5xlZVieey4l8KMKowcgN4BXYAIrOsdd5xBxnKD1hLyCVFN7"
  },
});
export default instance;
