import React from 'react';
import { Link } from 'react-router-dom';

const ResultSubmit = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-col items-center justify-between" style={{height: '100vh'}}>
        <div>
        <div className="banner-img card-banner"></div>
        <div className="wrap-body">
          <div className="line"></div>
          <div className="text">
            <div className="text-content">
              <p>
                Bộ phận Tuyển dụng đã nhận được thông tin ứng tuyển của bạn. Chúng tôi sẽ xem xét hồ sơ và
                liên hệ mời phỏng vấn những hồ sơ phù hợp yêu cầu tuyển dụng trong vòng 7 ngày làm việc. Cảm
                ơn bạn đã quan tâm đến công việc tại hệ thống nhà hàng cơm niêu Thiên Lý.
              </p>
              <p style={{ marginTop: '15px' }}>
                <span>
                  <Link to="/confirm-invite-pv/:id"><b>Điền lại form</b></Link>
                </span>
              </p>
            </div>
          </div>
        </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              height: '36px',
              aspectRatio: 'auto 74 / 24',
              width: '180px',
            }}
            src="https://comnieuthienly.vn/wp-content/uploads/2023/07/thien-ly-ngang_optimized-e1689073070596.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default ResultSubmit;
