import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Form from "./pages/Form";
import ProvideInfoEmployee from "./pages/ProvideInfoEmployee";
import HomeEmployee from "./pages/HomEmployee";
import EmployeeProfileDirectory from "./pages/EmployeeProfileDirectory";
import SupplementCandidateInfo from "./pages/SupplementCandidateInfo";
import Success from "./pages/Success";
import Done from "./pages/Done";
import "./App.css";
import ScrollToTop from "./uilt/ScrolltoTop";
import SuccessConfirm from "./pages/SuccessConfirm";
import SuccessDone from "./pages/SuccessDone";
import SupplementCandidateInfoNH from "./pages/SupplementCandidateInfoNH";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/form" element={<Form />} />
        <Route path="/success" element={<Success />} />
        <Route path="/confirm-success" element={<SuccessConfirm />} />
        <Route path="/done-success" element={<SuccessDone />} />
        <Route path="/done" element={<Done />} />
        <Route path="/finish/:id" element={<ProvideInfoEmployee />} />
        <Route path="/thong-tin-nhan-vien" element={<HomeEmployee />} />
        <Route
          path="/danh-muc-ho-so-nhan-vien"
          element={<EmployeeProfileDirectory />}
        />
        <Route path="/confirm/:id" element={<SupplementCandidateInfo />} />
        <Route path="/confirm-nh/:id" element={<SupplementCandidateInfoNH />} />
      </Routes>
    </Router>
  );
};

export default App;
