import React from "react";
import { Link, useNavigate } from "react-router-dom";

const ResultSubmit = () => {
  const history = useNavigate();

  return (
    <div className="container mx-auto">
      <div
        className="flex flex-col items-center justify-between"
        style={{ height: "100vh" }}
      >
        <div>
          <div className="banner-img card-banner"></div>
          <div className="wrap-body">
            <div className="line"></div>
            <div className="text">
              <div className="text-content">
                <p>
                  Bộ phận Tuyển dụng đã nhận được thông tin ứng tuyển của bạn.
                  Chúng tôi sẽ xem xét hồ sơ và liên hệ mời phỏng vấn những hồ
                  sơ phù hợp yêu cầu tuyển dụng trong vòng 7 ngày làm việc. Cảm
                  ơn bạn đã quan tâm đến công việc tại hệ thống nhà hàng Cơm
                  Niêu Thiên Lý.
                </p>
                <div style={{ height: 15 }}></div>
                <b>
                  *Lưu ý: Thư mời phỏng vấn sẽ được gửi qua email, bạn vui lòng
                  kiểm tra hộp mail và đến tham gia phỏng vấn như lịch hẹn nhé.
                </b>
                <p style={{ marginTop: "15px" }}>
                  <button
                    onClick={() => {
                      history(-1);
                    }}
                  >
                    <b>Điền lại form</b>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default ResultSubmit;

//    <div className="form-group">
//    <label className="text-style" htmlFor="info_questions">
//      Điểm mạnh của bản thân
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <textarea
//      type="text"
//      value={null}
//      onChange={(e) => handleInputQuestionsChange(e, "Điểm mạnh của bản thân ?")}
//      required
//      className="form-input"
//      placeholder="Nhập thông tin"
//    />
//  </div>
//  <div className="form-group">
//    <label className="text-style" htmlFor="permanent_address">
//      Điểm yếu của bản thân
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <textarea
//      type="text"
//      value=""
//      onChange={(e) => handleInputQuestionsChange(e, "Điểm yếu của bản thân ?")}
//      required
//      className="form-input"
//      placeholder="Nhập thông tin"
//    />
//  </div>
//  <div className="form-group flex justify-between items-center">
//    <label className="text-style" htmlFor="sex" style={{ margin: 0 }}>
//      Làm ngoài giờ, đi công tác xa?
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <label style={{ margin: 0 }}>
//      <input
//        type="radio"
//        value={0}
//        checked=""
//        onChange={(e) =>
//          handleRadioQuestionsChange(
//            e,
//            "Làm ngoài giờ, đi công tác xa ?"
//          )
//        }
//        required
//      />
//      Sắn sàng
//    </label>
//    <label style={{ margin: 0 }}>
//      <input
//        type="radio"
//        value={1}
//        checked=""
//        onChange={(e) =>
//          handleRadioQuestionsChange(
//            e,
//            "Làm ngoài giờ, đi công tác xa ?"
//          )
//        }
//        required
//      />
//      Đôi khi
//    </label>
//    <label style={{ margin: 0 }}>
//      <input
//        type="radio"
//        value={2}
//        required
//        checked=""
//        onChange={(e) =>
//          handleRadioQuestionsChange(
//            e,
//            "Làm ngoài giờ, đi công tác xa ?"
//          )
//        }
//      />
//      Hạn chế
//    </label>
//  </div>

//  <div className="form-group flex justify-between items-center">
//    <label className="text-style" htmlFor="sex" style={{ margin: 0 }}>
//      Đảm nhận công việc ở tỉnh/ TP khác
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <label style={{ margin: 0 }}>
//      <input
//        type="radio"
//        name="sex"
//        value={1}
//        checked={formData.sex == 1}
//        onChange={handleRadioChange}
//        required
//      />
//      Có thể
//    </label>
//    <label style={{ margin: 0 }}>
//      <input
//        type="radio"
//        name="sex"
//        value={2}
//        checked={formData.sex == 2}
//        onChange={handleRadioChange}
//        required
//      />
//      Không
//    </label>
//    <label style={{ margin: 0 }}>
//      <input
//        type="radio"
//        name="sex"
//        value={0}
//        required
//        checked={formData.sex == 0}
//        onChange={handleRadioChange}
//      />
//      Suy nghĩ
//    </label>
//  </div>

//  <div className="form-group">
//    <label className="text-style" htmlFor="sex">
//      Anh/Chị có bạn bè hay người thân đang làm việc tại Công ty Thiên
//      Lý hay Công ty cùng ngành không? (F&B)
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <div>
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="sex"
//          value={1}
//          checked={formData.sex == 1}
//          onChange={handleRadioChange}
//          required
//        />
//        Không
//      </label>
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="sex"
//          value={2}
//          checked={formData.sex == 2}
//          onChange={handleRadioChange}
//          required
//        />
//        Có, vui lòng ghi rõ thông tin (họ tên, vị trí, công ty)
//      </label>
//      <textarea
//        type="text"
//        name="permanent_address"
//        value={null}
//        onChange={handleChange}
//        required
//        className="form-input"
//        style={{ marginTop: "10px" }}
//        placeholder="Nhập thông tin"
//      />
//    </div>
//  </div>

//  <div className="form-group">
//    <label className="text-style" htmlFor="sex">
//      Anh/Chị đã từng nộp đơn dự tuyển vào làm việc tại Công ty hay
//      chưa?
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <div className="flex justify-between items-center">
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="sex"
//          value={1}
//          checked={formData.sex == 1}
//          onChange={handleRadioChange}
//          required
//        />
//        Chưa
//      </label>
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="sex"
//          value={2}
//          checked={formData.sex == 2}
//          onChange={handleRadioChange}
//          required
//        />
//        Rồi, vui lòng ghi rõ thời gian
//        <input
//          type="date"
//          name="birthday"
//          style={{ maxWidth: "150px", marginLeft: "20px" }}
//          value=""
//          onChange={handleChange}
//          required
//          className="form-input"
//        />
//      </label>
//    </div>
//  </div>

//  <div className="form-group">
//    <label className="text-style" htmlFor="sex">
//      Anh/Chị biết thông tin tuyển dụng qua kênh nào?
//      <span className="style-required" aria-label="Required question">
//        {" "}
//        *
//      </span>
//    </label>
//    <div className="flex justify-between items-center">
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="brand"
//          value={null}
//          checked={formData.sex == 1}
//          onChange={handleRadioChange}
//          required
//        />
//        Webiste tuyển dụng
//      </label>
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="brand"
//          value={null}
//          checked={formData.sex == 2}
//          onChange={handleRadioChange}
//          required
//        />
//        Bạn bè/Người thân
//      </label>
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="sex"
//          value={0}
//          required
//          checked={formData.sex == 0}
//          onChange={handleRadioChange}
//        />
//        Nhân viên Công ty giới thiệu
//      </label>
//      <label style={{ margin: 0 }}>
//        <input
//          type="radio"
//          name="sex"
//          value={0}
//          required
//          checked={formData.sex == 0}
//          onChange={handleRadioChange}
//        />
//        Khác
//      </label>
//    </div>
//  </div>
//  <div className="form-group">
//    <label className="text-style" htmlFor="permanent_address">
//      Người tham khảo tại công ty gần nhất
//    </label>
//    <textarea
//      type="text"
//      name="permanent_address"
//      value={null}
//      onChange={handleChange}
//      className="form-input"
//      placeholder="Nhập thông tin"
//    />
//  </div>
