import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../axiosConfig";

const Home = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.comnieuthienly.vn/api/form-cv")
      .then((response) => {
        setData(response?.data?.data?.description?.description);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setTimeout(() => {
          setError('');
        }, 2000);
        setError("Có lỗi xảy ra khi lấy dữ liệu.");
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mx-auto">
      <div
        className="flex flex-col items-center justify-between"
        style={{ height: "100vh" }}
      >
        <div>
          <div className="banner-img card-banner"></div>
          <div className="wrap-body">
            <div className="line"></div>
            <div dangerouslySetInnerHTML={{ __html: data }} />
            <div className="flex flex-row justify-start items-center">
              <button
                style={{ margin: "36px 0 15px 0" }}
                href="/form"
                onClick={() => {
                  window.location.href = "/form";
                }}
              >
                Next
                {/* <Link to={"/form"}>Next</Link> */}
              </button>
              {loading && (
                <div
                  style={{
                    marginLeft: "50px",
                  }}
                >
                  <div class="loader"></div>
                </div>
              )}
              {error && (
                <div id="errorPopup" className="error-popup">
                  {error}
                </div>
              )}
            </div>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default Home;
