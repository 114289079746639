import React, { useState, useEffect, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../axiosConfig";

const SupplementCandidateInfoNH = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(false);
  const { id } = useParams();

  const fetchDataCVInfo = async () => {
    setLoading(true);
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.comnieuthienly.vn/api/cv-info/${id}?is_confirm=true`
      );
      setFormData(response.data.data); // Cập nhật state với dữ liệu trả về từ API
      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDataCVInfo();
  }, []);

  return (
    <div className="">
      <div className="flex flex-col items-center justify-center mb-8">
        <div className="card-banner banner-img p-style"></div>
        <div className="wrap-content p-style">
          <div className="line"></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                color: "#000",
                marginBottom: "1rem",
              }}
            >{`Cảm ơn ${formData?.name ?? ""} !`}</span>
            <span className="text-heading">ĐÃ XÁC NHẬN THAM GIA PHỎNG VẤN</span>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default SupplementCandidateInfoNH;
