import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "../axiosConfig";

const EmployeeProfileDirectory = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://api.comnieuthienly.vn/api/form-cv")
      .then((response) => {
        setData(response?.data?.data?.description?.description);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setTimeout(() => {
          setError('');
        }, 2000);
        setError("Có lỗi xảy ra khi lấy dữ liệu.");
        setLoading(false);
      });
  }, []);

  return (
    <div className="container mx-auto">
      <div
        className="flex flex-col items-center justify-between"
        style={{ height: "100vh" }}
      >
        <div>
          <div className="banner-img card-banner"></div>
          <div className="wrap-body">
            <div
              className="line"
              style={{ padding: "12px 24px", height: "auto" }}
            >
              <span
                className="text-content"
                style={{ color: "rgb(0, 0, 0)", fontSize: "13pt" }}
              >
                DANH MỤC HỒ SƠ NHÂN VIÊN
              </span>
            </div>
            <div className="text-content" style={{ marginTop: "50px" }}>
              <p>
                Bạn vui lòng chuẩn bị bộ hồ sơ và nộp về phòng HCNS theo danh
                mục bên dưới:
              </p>
              <div className="line-bottom"></div>
              <h2>
                <b>KHỐI NHÀ HÀNG</b>
              </h2>
              <p>
                <b>1. Thời hạn nộp hồ sơ:</b>trong vòng 07 ngày (đối với phục
                vụ, thu ngân, tạp vụ) và 14 ngày (đối với các vị trí bếp) tính
                từ ngày làm việc đầu tiên.
              </p>
              <p>
                <b>
                  2. Danh mục hồ sơ:<br></br>
                </b>
                - 1 Bản CCCD/CMND photo công chứng (thời hạn không quá 6 tháng)
                <br></br>
              </p>
              <p>- Giấy khám sức khỏe theo thông tư 32 phụ lục 1</p>
              <p style={{ marginLeft: "20px" }}>
                + Đối với các vị trí Bếp: nộp trong vòng 14 ngày kể từ ngày nhận
                việc.<br></br>+ Đối với Phục Vụ và Tạp Vụ: nộp bổ sung khi có
                yêu cầu.<br></br>
              </p>
              <p>
                - Form mẫu tuyển dụng nội bộ: (liên hệ QLNH để nhận và điền)
                <br></br>
              </p>
              <p style={{ marginLeft: "20px" }}>
                + 1 Phiếu thông tin ứng viên và đánh giá phỏng vấn <br></br>+ 1
                Bản cam kết và xác nhận thông tin <br></br>+ 1 Bản cam kết thuế{" "}
                <br></br>+ Hợp đồng nhân viên <br></br>
              </p>
              <div className="line-bottom"></div>
              <h2>
                <b>KHỐI VĂN PHÒNG:</b>
              </h2>
              <p>
                <b>1. Thời hạn nộp hồ sơ:</b>trong vòng 15 ngày kể từ ngày nhận
                việc và trước ngày 3 hằng tháng.
              </p>
              <p>
                <b>
                  2. Danh mục hồ sơ:<br></br>
                </b>
                - 1 Đơn xin việc <br></br>
              </p>
              <p>
                - 1 Bản Sơ yếu lý lịch (công chứng thời hạn không quá 6 tháng)
              </p>
              <p>
                - 1 Giấy khám sức khỏe của cơ sở y tế có thẩm quyền (thời hạn 06
                tháng) <br></br>
              </p>
              <p>
                - 1 CMND/CCCD (photo công chứng thời hạn không quá 6 tháng){" "}
                <br></br>
              </p>
              <p>
                - 1 Bằng cấp và các chứng chỉ có liên quan (photo công chứng){" "}
                <br></br>
              </p>

              <p style={{ marginTop: "25px" }}>
                * Lưu ý: Quy định đối với trường hợp không nộp hoặc thiếu hồ sơ:
                Công ty sẽ hoãn chi lương theo quy định số{" "}
                <b>TL-HRD-05 đã ban hành ngày 15/11/2023</b> cho đến khi hồ sơ
                đầy đủ (số tiền hoãn chi sẽ được chi trả vào kỳ chi lương gần
                nhất tính từ ngày bổ sung đầy đủ hồ sơ).
              </p>
            </div>

            {/* <div dangerouslySetInnerHTML={{ __html: data }} /> */}
            <div style={{ margin: "36px 0 15px 0" }} className="flex flex-row content-start items-center">
              <button style={{ marginRight: "25px" }}>
                <Link to={"/danh-muc-ho-so-nhan-vien"}>Trở lại</Link>
              </button>
              <button >
                <Link to={"/finish-cv/:id"}>Next</Link>
              </button>
              {/* {loading && <div class="loader"></div>} */}
              {/* {error && (
                <div id="errorPopup" className="error-popup">
                  {error}
                </div>
              )} */}
            </div>
          </div>
        </div>
        <a
          className="style-link flex flex-row items-center justify-center"
          href="https://comnieuthienly.vn/"
          target="_blank"
          rel="noopener noreferrer"
        >
           <img
            style={{
              aspectRatio: "auto 74 / 24",
              width: "200px",
            }}
            src="https://i.imgur.com/nB8LOLM.png"
            alt="img-logo-thien-ly"
          />
        </a>
      </div>
    </div>
  );
};

export default EmployeeProfileDirectory;
